import React from 'react';
import { usePreferences } from '../../utils/contexts/preferences-context';
import Items from './Items';
import PreferredDefaultScreen from '../UI/PreferredDefaultScreen';

export default function Preferred() {
    const { items } = usePreferences();

    return (
        <div className="preferred">
            {0 === items.length ?
                <PreferredDefaultScreen /> :
                <Items items={items} />
            }
        </div>
    );
}
