import { IonSpinner } from '@ionic/react';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import sumUpClient from '../../logic/sumUp';
import { useCart } from '../../utils/contexts/cart-context';
import { useRestaurant } from '../../utils/contexts/restaurant-context';

export default function SumupWidget(props) {
    const { order } = props;

    const [loading, setLoading] = React.useState(true);

    const { type, id } = useParams();
    const { products, type: orderType } = useCart();
    const history = useHistory();
    const { restaurant } = useRestaurant();

    React.useLayoutEffect(() => {
        async function generateCheckoutId() {
            console.log({ order })
            let payment;
            if (orderType === "delivery") {
                payment = restaurant.orders.delivery.payment_methods.find(payment => payment.name === 'sumup');
            } else if (orderType === "takeaway") {
                payment = restaurant.orders.takeaway.payment_methods.find(payment => payment.name === 'sumup');
            } else if (orderType === "restaurant") {
                payment = restaurant.orders.tableservice.payment_methods.find(payment => payment.name === 'sumup');
            } else if (orderType === "shipment") {
                payment = restaurant.orders.shipment.payment_methods.find(payment => payment.name === 'sumup');
            }

            const checkout = await sumUpClient.doGetCheckout(order.order.total, "EUR", payment.account, products.map(product => product.name));

            return checkout.id;
        }

        async function renderSumupWidget(checkoutID) {
            var sumupCard = await window.SumUpCard.mount({
                checkoutId: checkoutID,
                onResponse: function (status, body) {
                    if (status === "success") {
                        sumupCard.unmount();
                        if (type === "t" || type === "d") {
                            history.push(`/${type}/q/${id}/orders/awaitsms/?provider=sumup&transactionid=${body.checkout_reference}`);
                        }
                        if (type === "r") {
                            history.push(`/${type}/q/${id}/orders/success/?provider=sumup&transactionid=${body.checkout_reference}`);
                        }
                    } if (body.status === "FAILED") {
                        alert("Pagamento non riuscito, riprova.");
                    }
                },
                amount: order.order.total.toString(),
                currency: "EUR",
                id: "sumup-widget",
                onLoad: function () {
                    setLoading(false);
                },
            })

        }

        setTimeout(async () => {
            const checkoutId = await generateCheckoutId();
            renderSumupWidget(checkoutId);
        }, 1000);
    }, [
        history,
        id,
        order,
        orderType,
        products,
        restaurant.orders.delivery.payment_methods,
        restaurant.orders.shipment.payment_methods,
        restaurant.orders.tableservice.payment_methods,
        restaurant.orders.takeaway.payment_methods,
        type
    ]);

    return (
        <>
            {loading ? <div className="z-10 p-10"><IonSpinner name="crescent" />Attendi</div> : null}
            <div id="sumup-widget"></div>
        </>
    )
}
