import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useCart } from '../utils/contexts/cart-context';
import { useHistory, useParams } from 'react-router';

export default function OrdersCancelPage() {
    const { t } = useTranslation();

    const { clean } = useCart();
    const { type, id } = useParams();
    const history = useHistory();

    const onClickHandler = () => {
        clean();
        history.push(`/${type}/q/${id}/menu`);
    }

    return (
        <IonPage>
            <IonContent>
                <section id="orders-cancel-page" className="container mx-auto pt-12 px-8 font-sans text-center">
                    <h3 className="mb-4 text-2xl font-medium">{t("orderscancelpage.h3_1")}</h3>
                    <button onClick={onClickHandler} className="block py-3 px-3 m-auto rounded-full bg-mycia-green">{t("orderscancelpage.button_1")}</button>
                </section>
            </IonContent>
        </IonPage>
    );
}
