import React from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { IonPage, IonContent } from '@ionic/react';

export default function ReviewSuccessPage() {
    const { type, id } = useParams();
    const { t } = useTranslation();

    const tip = new URLSearchParams(window.location.search).get('tip');
    const success_url = `${window.location.origin}/${type}/q/${id}/tip/success`;
    const tackpay_bucket_id = new URLSearchParams(window.location.search).get('tackpay_bucket_id');
    const tackPayUrl = `${process.env.REACT_APP_TACKPAY}/checkout/${tackpay_bucket_id}?&success_url=${success_url}&error_url=error_url`;

    return (
        <IonPage>
            <IonContent>
                <section id="success-page" className="relative pt-8 pb-32 font-sans text-center">
                    <p className="font-bold text-xl">Valuta ristorante</p>

                    <img src="/assets/images/review_complete.png" className="w-1/2 m-auto pt-12 pb-10" alt="checkout success" />

                    {tip ?
                        <div className="my-4 px-8">
                            <p className="font-bold">Siamo felici che la tua esperienza nel locale sia stata positiva!</p>
                        </div> :
                        <div className="my-4 px-8">
                            <h2 className="font-bold text-xl">Il tuo voto è stato registrato!</h2>
                            <p className="text-sm">Grazie per contribuire a migliorare l’esperienza della community di MyCIA.</p>
                        </div>}

                    {tip ?
                        <div>
                            <a type="button" target="_blank" rel="noreferrer noopener" href={tackPayUrl} className="block relative w-full cursor-pointer mt-4 py-3 rounded-md font-medium bg-[#93E6A2] text-white">
                                <img src="/assets/images/tip.png" className="absolute left-3 inline w-8" alt="tip icon" />
                                MANCIA
                            </a>
                            <div className="fixed bottom-0 w-full flex flex-col justify-center mt-8 px-4 py-6 bg-white">
                                <a href={`/${type}/q/${id}/menu`} className="block py-3 underline">{t("successpage.button_1")}</a>
                            </div>
                        </div> :
                        <div className="fixed bottom-0 w-full flex flex-col justify-center mt-8 px-4 py-6 bg-white">
                            <a href={`/${type}/q/${id}/menu`} className="block py-3 rounded-full text-white bg-mycia-blue">{t("successpage.button_1")}</a>
                        </div>}

                </section>

            </IonContent>
        </IonPage>
    );
}
