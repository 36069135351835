import React from 'react';
import Ingredient from './Ingredient';

export default function Ingredients(props) {
    const { ingredients } = props;

    let total = ingredients.length;

    return (
        <p className="ingredients flex flex-wrap text-sm italic">
            {ingredients.length === 0 ?
                ("No ingredients.") :
                (ingredients.map((ingredient, index) => <Ingredient ingredient={ingredient} total={total} index={index} key={index} />))}
        </p>
    );
}
