import React from 'react';
import Allergens from './Allergens';
import formats from '../../utils/formats';

export default function IngredientWithAllergens(props) {
    const { ingredient, total, index } = props;

    //Senza glutine check. It will be removed in the future
    ingredient.allergens.forEach((allergen, i) => {
        if (allergen.name === "Senza glutine") {
            ingredient.allergens.splice(i);
        }
    });

    return (
        <span className="ingredient-with-allergens mr-2">
            <span className="mr-1">{formats.capitalize(ingredient.name)}</span>
            <span className="not-italic">(<span className="italic"><Allergens allergens={ingredient.allergens} /></span>){(Boolean(Number(total - index - 1))) ? "," : null}</span>
        </span>
    );
}
