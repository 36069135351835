import React from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { IonPage, IonContent, IonIcon } from '@ionic/react';
import FormTable from '../components/cart/FormTable';
import { useCart } from '../utils/contexts/cart-context';
import { chevronBackOutline } from 'ionicons/icons';
import { useRestaurant } from '../utils/contexts/restaurant-context';
import forms from '../utils/forms';
import formats from '../utils/formats';

export default function TablesPage() {
    let storage = {};

    const history = useHistory();
    const { type, id } = useParams();

    const { t } = useTranslation();

    const { number, products, quantity, setTable, total, customers, payed, order_note, addOrderNote } = useCart();
    const { restaurant } = useRestaurant();

    const [terms, setTerms] = React.useState(false);
    const [checkTables, setCheckTables] = React.useState(false);
    const [form, setForm] = React.useState({ "number": "" });

    const buttonDisabled = "opacity-50 + cursor-not-allowed";

    const onClickHandler = () => {
        if (form.number) {
            setTable(form.number);

            let localTotal = total + (restaurant.cover_charge * customers);
            let amount = localTotal * 100;
            let commissions = (localTotal * 0.02) * 100;

            storage["restaurant"] = restaurant;
            storage["order"] = {
                "amount": amount,
                "commissions": commissions,
                "customers": customers,
                "number": number,
                "payed": payed,
                "products": products,
                "quantity": quantity,
                "table": form.number,
                "timestamp": Date.now(),
                "total": localTotal,
                "type": "restaurant",
                "note": order_note,
            };
            storage["transaction"] = {
                "id": forms.generateRandomID("cash_"),
                "provider": "cash",
            }

            window.localStorage.setItem("__mycontactlessmenu_payload__", JSON.stringify(storage));

            history.push(`/${type}/q/${id}/orders/success/?provider=cash`);
        }
    };

    const onCheckoutHandler = () => {
        if (form.number) {
            setTable(form.number);

            history.push(`/${type}/q/${id}/orders/payments`);
        }
    };

    const onBackHandler = () => history.push(`/${type}/q/${id}/orders`);

    function handleTerms() {
        setTerms(terms => !terms);
    };

    function renderText() {
        if (type === "h") {
            return <p className="text-sm">{t("roomservice.p_1")}.</p>
        } else if (type === "s") {
            return <p className="text-sm">{t("bathhouseservice.p_1")}.</p>
        } else {
            return <p className="text-sm">{t("tableform.p_1")}.</p>
        }
    }

    // useLayoutEffect(() => {
    //     async function pixelLog() {
    //         try {
    //             await window.fbq('track', 'ViewContent');
    //         } catch (e) {
    //             console.log({ pixelError: e })
    //         }
    //     }

    //     pixelLog();
    // }, [])

    return (
        <IonPage>
            <IonContent style={{ "--background": "#f0edec" }}>
                <section id="tables-page" className="relative h-screen pt-6 font-sans">
                    <div className="flex items-center">
                        <button type="button" onClick={onBackHandler} className="back-button absolute flex items-center justify-center ml-4 mb-6 p-3 rounded-full shadow-lg bg-white">
                            <IonIcon slot="icon-only" icon={chevronBackOutline}></IonIcon>
                        </button>
                        <h2 className="pb-4 w-full text-center font-bold text-2xl">{t("tablespage.h2_1")}</h2>
                    </div>

                    <div className="px-6">
                        <div className="py-4">{renderText()}</div>
                        <div className="px-6 py-2 bg-white rounded-lg">
                            <FormTable setForm={setForm} form={form} checkTables={checkTables} setCheckTables={setCheckTables} />

                            <div className="flex justify-center items-center pt-8">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 mr-6" viewBox="0 0 83.99 89.21"><g data-name="Livello 2"><path d="M56 0l27.81 27.82L84 89H18.43l.18-5.22h60l.17-52.34H52.34l.17-26.25H15.82l.18 5.24h2.26a7.84 7.84 0 013.13.61 9.15 9.15 0 012.52 1.65 6.26 6.26 0 011.65 2.53 13.7 13.7 0 01.7 3l-.18 44.86-12.69 26.13L0 63.3l.17-45a5.57 5.57 0 01.53-3 8.92 8.92 0 011.73-2.53A7.81 7.81 0 015 11a7.6 7.6 0 012.87-.61h2.95L10.61 0zM21 18.26a6.88 6.88 0 01-.26-1 1.37 1.37 0 00-.52-.87 3.39 3.39 0 00-.87-.53 3.24 3.24 0 00-1.13-.17H8a2.51 2.51 0 00-1 .17 4.18 4.18 0 00-.79.53 1.85 1.85 0 00-.61.87l-.34 1 .17 7.82h15.44zm-5.7 55.12L21 61.9l-.17-30.6h-5.01L15.65 63h-5l-.22-31.7h-5l-.21 30.6 5.91 11.48zM57.73 9l-.17 17.22H75L57.56 9z" data-name="Livello 1" /></g></svg>
                                <p>{t("tablespage.p_3")}</p>
                            </div>
                            <div>
                                <input className="w-full mt-2 py-3 px-4 rounded-lg bg-grey-200" placeholder={t("tablespage.p_3")} name="notes" value={order_note} onChange={(e) => addOrderNote(e.target.value)} />
                            </div>

                            {formats.shouldDisplayRestaurantCoverCharge(restaurant, type) ?
                                <p className="my-6 py-4 text-center bg-white border-t-2">Costo del servizio {restaurant.cover_charge} € a persona</p> :
                                null}
                        </div>

                        <div className="py-6 text-sm text-center"> <input type="checkbox" id="terms" name="terms" onChange={handleTerms} />
                            <label className="pl-2">{t("formtable.lable_1.span_1")} <a className="underline" href="https://www.cartaidentitalimentare.com/privacy-esercizi-commerciali/" target="_blank" rel='noreferrer'>{t("formtable.lable_1.a_1")}</a> {t("formtable.lable_1.span_2")}</label>
                        </div>
                    </div>

                    <div className="w-full flex flex-col justify-center p-4">
                        {formats.shouldDisplayRestaurantCoverCharge(restaurant, type) ?
                            <p className="my-6 py-4 text-center font-bold">Totale aggiornato: {total + (restaurant.cover_charge * customers)} €</p> :
                            <p className="my-6 py-4 text-center font-bold">Totale aggiornato: {total} €</p>}

                        {formats.shouldDisplayPaymentsV2(restaurant, "restaurant") ? <button type="submit" form="table-form" disabled={!(terms && checkTables)} onClick={onCheckoutHandler} className={`block py-2 rounded-full bg-mycia-green ${(terms && checkTables) ? "" : buttonDisabled}`}>{t("tablespage.button_1")}</button> : null}
                        {formats.shouldDisplayPaymentsButtonV2(restaurant, "cash", "restaurant") ? <button type="submit" form="table-form" disabled={!(terms && checkTables)} onClick={onClickHandler} className={`block mt-4 py-2 rounded-full bg-mycia-green ${(terms && checkTables) ? "" : buttonDisabled}`}>{id === "HWwJ4J1Q0" ? "PAGAMENTO POS ALL'OMBRELLONE" : t("tablespage.button_2")}</button> : null}
                    </div>
                </section>
            </IonContent>
        </IonPage>
    );
}
