export default function Nutrient(props) {
    const { nutrient, index } = props;

    return (
        <div className="nutrient flex justify-between" key={index}>
            <dt className="capitalize">{nutrient[0].replace("_", " ")}</dt>
            <dd>{nutrient[1]}<span className="text-sm text-grey-600">g / 100g</span></dd>
        </div>
    );
}
