import React from 'react';
import { useTranslation } from 'react-i18next';
import formats from '../../utils/formats';
import { useRestaurant } from '../../utils/contexts/restaurant-context';

export default function CtaItem(props) {
    const { element, closerHandler } = props;

    const { restaurant } = useRestaurant();

    const [hasDrinkVariations, setHasDrinkVariations] = React.useState(false);
    const [hasDishVariations, setHasDishVariations] = React.useState(false);
    const [hasDishAdditions, sethasDishAdditions] = React.useState(false);

    const { t } = useTranslation();

    React.useEffect(() => {
        if (element.glass_price && Number(element.glass_price) !== 0) {
            setHasDrinkVariations(true);
        }
        if (element.variations && Number(element.variations.length) !== 0) {
            setHasDishVariations(true);
        }

        if (element.additions && Number(element.additions.length) !== 0) {
            sethasDishAdditions(true);
        }
    }, [element]);

    return (
        <div className="cta-item pb-10 overflow-y-scroll">
            <div className="relative overflow-y-scroll flex flex-col justify-between bg-white">
                <div className="flex flex-col py-4 border-b border-b-grey-200">
                    <div className="flex items-center px-4 pb-4 border-b border-b-grey-200">
                        <p className="flex-1">{formats.capitalize(element.name)}</p>
                        <p className="flex-none font-bold">{formats.formatPrice(element.price, restaurant.id)}</p>
                    </div>

                    {hasDrinkVariations &&
                        <div className="p-3">
                            <p className="font-bold">Al calice</p>
                            <div className={`flex items-center mt-4 rounded-lg bg-grey-200`}>
                                <div className="flex-1">

                                    <p className={"block p-4"}>Calice</p>
                                </div>
                                <p className="py-4 px-3">{formats.formatPrice(element.glass_price, restaurant.id)}</p>
                            </div>
                        </div>}

                    {hasDishVariations &&
                        <div className="dish-variations pt-3">
                            <div className="p-3">
                                <p className="font-bold">Varianti del piatto</p>
                                {element.variations.map((addition, index) =>
                                    <div key={index} className={"flex items-center mt-4 rounded-lg bg-grey-200 border-solid border-l-8"}>
                                        <div className="flex-1">
                                            <p className={"block p-4"}>{addition.name}</p>
                                        </div>
                                        {(Number(addition.price) !== 0) && <label className="py-4 px-3">{formats.formatPrice(addition.price, restaurant.id)}</label>}
                                    </div>
                                )}
                            </div>
                        </div>}

                    {hasDishAdditions &&
                        <div className="dish-additions pt-3">
                            <div className="p-3">
                                <p className="font-bold">Aggiunte disponibili</p>
                                {element.additions.categories.map((category, index) =>
                                    <div>
                                        <p className="pt-4">{category.name} max: {category.max_picks_allowed}</p>
                                        {category.additions.map(addition =>
                                            <div key={index} className={"flex items-center mt-4 rounded-lg bg-grey-200 border-solid border-l-8"}>
                                                <div className="flex-1">
                                                    <p className={"block p-4"}>{addition.name}</p>
                                                </div>
                                                {(Number(addition.price) !== 0) && <label className="py-4 px-3">{formats.formatPrice(addition.price, restaurant.id)}</label>}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>}
                </div>
            </div>

            <div className="flex flex-col p-4 bg-white">
                <button type="button" onClick={closerHandler} className="block mt-2 py-2 rounded-full underline">{t("item.button_2")}</button>
            </div>
        </div>
    );
}
