import { IonModal } from '@ionic/react';
import React from 'react';
import DeliveryPopupModalContent from './DeliveryPopupModalContent';

export default function DeliveryPopupModalTrigger() {
    const [showModal, setShowModal] = React.useState(false);

    const onCloseHandler = () => setShowModal(false);

    React.useLayoutEffect(() => {
        setTimeout(() => setShowModal(true), 1000);
    }, []);

    return (
        <div className="delivery-popup-modal-trigger py-1">
            <IonModal cssClass="delivery-popup-modal-trigger" mode="md" isOpen={showModal} onDidDismiss={onCloseHandler}>
                <DeliveryPopupModalContent onClickHandler={onCloseHandler} />
            </IonModal>
        </div>
    );
}
