import React from 'react';
import { useParams } from 'react-router';
import { IonContent, IonPage } from "@ionic/react";
import { useTracker } from '../utils/contexts/tracker-context';
import RestaurantLoader from '../components/containers/RestaurantLoader';
import Splash from '../components/UI/Splash';
import LanguageSelector from '../components/LanguageSelector';
import features from '../utils/features';
import theme from '../utils/theme';
import { useRestaurant } from '../utils/contexts/restaurant-context';

export default function SplashPage(props) {
    const { trackDataFn } = useTracker();

    const [palette, setPalette] = React.useState("");

    const { type } = useParams();
    const { restaurant } = useRestaurant();

    React.useLayoutEffect(() => {
        if (restaurant) {
            if (restaurant.custom_webapp_theme) {
                const styleNode = document.createElement("style");
                styleNode.innerHTML = atob(restaurant.custom_webapp_theme);
                document.querySelector("head").append(styleNode);
            } else if (!process.env.REACT_APP_THEME) {
                theme.getTheme(restaurant.id, setPalette);
            } else {
                setPalette(process.env.REACT_APP_THEME);
            }
        }

        async function track() {
            restaurant && await trackDataFn(restaurant.id, restaurant.token, "usersession", { "type": type }, restaurant.has_orders);
        }

        track();
    }, [restaurant, trackDataFn, type]);

    return (
        <IonPage>
            <IonContent style={theme.getThemeIonComponents(palette).splashPage}>
                <section id="splash-page" className="font-sans">
                    <RestaurantLoader component={Splash} {...props} />
                </section>
                {features.toggler().includeLanguageSelector() && <LanguageSelector onMenu={false} />}
            </IonContent>
        </IonPage>
    );
}
