import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

export default function TipSuccessPage() {
    const { id } = useParams();

    return (
        <IonPage>
            <IonContent>
                <section id="whatsapp-success-page" className="relative pt-8 pb-32 font-sans text-center">
                    <div className="px-8">
                        <h2 className="font-bold text-4xl">Complimenti!</h2>
                        <p className="mt-8">La mancia è stata pagata con successo.</p>
                        <p className="mt-8 text-sm">  Siamo felici che la tua esperienza nel locale sia stata positiva!</p>
                    </div>
                    <img src="/assets/svg/tip_success.svg" className="w-1/2 m-auto pt-12" alt="checkout success" />


                    <div className="fixed bottom-0 w-full flex flex-col justify-center mt-8 px-4 py-6 bg-white">
                        <Link to={`/r/q/${id}`} className="block py-2 rounded-full bg-mycia-green">
                            <div className="flex items-center justify-center px-6">
                                <p className="pl-3">Torna al menu</p>
                            </div>
                        </Link>
                    </div>
                </section>
            </IonContent>
        </IonPage >
    )
}
