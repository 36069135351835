import React from "react";
import subscriptionModalClient from "../../logic/subscriptionModal";
import { IonIcon } from "@ionic/react";
import { close } from "ionicons/icons";
import { useParams } from "react-router";
import "./SubscriptionModalContentStyle.css";

export default function SubscriptionModalContent(props) {
    const { closerHandler } = props;

    const { id } = useParams();
    // const { t } = useTranslation();

    async function submitHandler(evt) {
        evt.preventDefault();
        const form = new FormData(evt.target);
        const formObj = Object.fromEntries(form);
        await subscriptionModalClient.doPost(formObj);
        let storage = window.localStorage.getItem("has_subscription");
        let obj = [{ id }];
        if (storage) {
            obj = JSON.parse(storage)
            obj.push({ id });
        }

        window.localStorage.setItem("has_subscription", JSON.stringify(obj));
        closerHandler();
    };

    return (
        <div className="subscription-modal-content overflow-auto text-black">
            <div className="relative mb-4 px-4">
                <button type="button" onClick={closerHandler} className="z-10 absolute text-2xl top-0 right-0 flex items-center justify-center mt-4 p-3 pb-0">
                    <IonIcon slot="icon-only" icon={close}></IonIcon>
                </button>
            </div>

            <form className="pb-4 p-1 px-8 mb-3" onSubmit={submitHandler}>
                <p className="px-3 border-b border-b-gray-600">Vuoi ricevere offerte, scconti o promozioni dal locale e da MyCIA? 😍</p>
                <fieldset className="pt-3 text-sm">
                    <input type="hidden" name="ris_id" value={id} />
                    <div>
                        <label htmlFor="name" className="sr-only"></label>
                        <input type="text" name="name" id="name" className="w-full mt-2 py-3 px-4 rounded-lg bg-grey-200" placeholder="Scrivi il tuo nome" required />
                    </div>
                    <div>
                        <label htmlFor="lastname" className="sr-only"></label>
                        <input type="text" name="lastname" id="lastname" className="w-full mt-2 py-3 px-4 rounded-lg bg-grey-200" placeholder="Scrivi il tuo cognome" required />
                    </div>
                    <div>
                        <label htmlFor="email" className="sr-only"></label>
                        <input type="email" name="email" id="email" className="w-full mt-2 py-3 px-4 rounded-lg bg-grey-200" placeholder="Scrivi la tua Email" required />
                    </div>
                    <div>
                        <label htmlFor="phone" className="sr-only"></label>
                        <input type="text" name="phone" id="phone" className="w-full mt-2 py-3 px-4 rounded-lg bg-grey-200" placeholder="Telefono" required />
                    </div>
                </fieldset>
                <div className="pt-6">
                    <fieldset className="text-xs">
                        <div>
                            <input type="checkbox" name="agree" id="agree" className="mx-3" required />
                            <label>Accetto i <a href="https://www.iubenda.com/privacy-policy/67338862" className="underline">termini e le condizioni del servizio</a> e acconsento a MyCIA di inviarmi offerte, sconti o promozioni</label>
                        </div>
                        <div>
                            <input type="checkbox" name="second-agree" id="second-agree" className="mx-3" required />
                            <label>Accetto i secondi <a href="https://..." className="underline cursor-pointer">termini e le condizioni del servizio</a> e acconsento al locale di inviarmi offerte, sconti o promozioni</label>

                        </div>
                    </fieldset>
                    <button type="submit" className="my-2 py-2 px-4 w-full border border-blue-400 rounded-full">Invia</button>
                </div>
            </form>

            <div className="text-center">
                <button className="underline" onClick={closerHandler}>Chiudi</button>
            </div>
        </div>
    );
}
