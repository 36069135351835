import React from "react";
import { IonIcon } from "@ionic/react";
import { close } from "ionicons/icons";
import { useParams } from "react-router";
import formats from "../../utils/formats";
import "./CiaLoginModalContentStyle.css";

export default function CiaLoginModalContent({ closerHandler }) {
    const { type, id } = useParams();

    return (
        <div className=" cia-login-modal-trigger overflow-auto p-4 flex flex-col gap-4 text-black">
            <div className="sticky top-0">
                <div className="flex flex-row justify-between">
                    <span className="py-1 px-2 rounded-full text-sm bg-mycia-red text-white">novità</span>

                    <button type="button" onClick={closerHandler} className="text-2xl flex items-center justify-center">
                        <IonIcon slot="icon-only" icon={close}></IonIcon>
                    </button>
                </div>
            </div>

            <img src="/assets/login-cta-img.png" alt="login call to action" />

            <p className="text-center">Da oggi utilizza la tua <span className="underline font-bold">Carta d'Identità Alimentare</span>® nei menù digitali! Potrai vedere ogni menù di MyCIA filtrato secondo i tuoi gusti e preferenze.</p>

            <a href={formats.loginUrl(type, id)} onClick={closerHandler} className="block cursor-pointer py-3 px-8 rounded-full font-medium border-2 border-mycia-red">Accedi con Carta d'Identità Alimentare</a>

            <p className="text-center">
                <a href={`https://login.mycia.it/register?redirect=${encodeURIComponent(formats.mcmUrl(type, id))}`} target="_blank" rel="noreferrer" className="underline text-mycia-red cursor-pointer">Crea un account gratis</a>
            </p>
        </div>
    );
}
