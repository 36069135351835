import React from 'react';
import { useTranslation } from 'react-i18next';
import { IonIcon } from '@ionic/react';
import assets from '../../utils/assets';
import { chevronBackOutline } from 'ionicons/icons';

export default function AllergensModalContent(props) {
    const { closerHandler } = props;

    const { t } = useTranslation();

    return (
        <div className="allergens-modal-content text-black mx-auto pt-8 pb-16 overflow-y-scroll">
            <button type="button" onClick={closerHandler} className="z-10 absolute top-0 left-0 flex items-center justify-center ml-2 mt-4 p-3 rounded-full shadow-lg bg-white">
                <IonIcon slot="icon-only" icon={chevronBackOutline}></IonIcon>
            </button>

            <h1 className="m-4 py-4 text-center text-2xl font-medium border-b border-b-gray-600">{t("allergensmodalcontent.h1_1")}</h1>

            <ul className="mt-8 py-4">
                <li className="flex mb-4 p-4">
                    <div className="flex-none w-8"><img src={assets.assetsPath("images/icons/allergie/solfiti.png")} alt={t("allergensmodalcontent.li_1.img_1")} /></div>
                    <div className="ml-8">
                        <p><b>{t("allergensmodalcontent.li_1.b_1")}</b> {t("allergensmodalcontent.li_1.fragment_1")}</p>
                    </div>
                </li>
                <li className="flex mb-4 p-4 bg-gray-200">
                    <div className="flex-none w-8"><img src={assets.assetsPath("images/icons/allergie/arachidi.png")} alt={t("allergensmodalcontent.li_2.img_1")} /></div>
                    <div className="ml-8">
                        <p><b>{t("allergensmodalcontent.li_2.b_1")}</b> {t("allergensmodalcontent.li_2.fragment_1")}</p>
                    </div>
                </li>
                <li className="flex mb-4 p-4">
                    <div className="flex-none w-8"><img src={assets.assetsPath("images/icons/allergie/glutine.png")} alt={t("allergensmodalcontent.li_3.img_1")} /></div>
                    <div className="ml-8">
                        <p><b>{t("allergensmodalcontent.li_3.b_1")}</b> {t("allergensmodalcontent.li_3.fragment_1")}</p>
                        <ul>
                            <li>{t("allergensmodalcontent.li_3.li_1")}</li>
                            <li>{t("allergensmodalcontent.li_3.li_2")}</li>
                            <li>{t("allergensmodalcontent.li_3.li_3")}</li>
                        </ul>
                    </div>
                </li>
                <li className="flex mb-4 p-4 bg-gray-200">
                    <div className="flex-none w-8"><img src={assets.assetsPath("images/icons/allergie/crostacei.png")} alt={t("allergensmodalcontent.li_4.img_1")} /></div>
                    <div className="ml-8">
                        <p><b>{t("allergensmodalcontent.li_4.b_1")}</b> {t("allergensmodalcontent.li_4.fragment_1")}</p>
                    </div>
                </li>
                <li className="flex mb-4 p-4">
                    <div className="flex-none w-8"><img src={assets.assetsPath("images/icons/allergie/frutta-guscio.png")} alt={t("allergensmodalcontent.li_5.img_1")} /></div>
                    <div className="ml-8">
                        <p><b>{t("allergensmodalcontent.li_5.b_1")}</b> {t("allergensmodalcontent.li_5.fragment_1.fragment_1")} <b>{t("allergensmodalcontent.li_5.b_2")}</b> (Amygdalus communis L.), <b>{t("allergensmodalcontent.li_5.b_3")}</b> (Corylus avellana), <b>{t("allergensmodalcontent.li_5.b_4")}</b> (Juglans regia), <b>{t("allergensmodalcontent.li_5.b_5")}</b> (Anacardium occidentale), <b>{t("allergensmodalcontent.li_5.b_6")}</b> [Carya illinoinensis (Wangenh.) K. Koch], <b>{t("allergensmodalcontent.li_5.b_7")}</b> (Bertholletia excelsa), <b>{t("allergensmodalcontent.li_5.b_8")}</b> (Pistacia vera), <b>{t("allergensmodalcontent.li_5.b_9")}</b> {t("allergensmodalcontent.li_5.fragment_1.fragment_2")} <b>{t("allergensmodalcontent.li_5.b_10")}</b> (Macadamia ternifolia) {t("allergensmodalcontent.li_5.fragment_1.fragment_3")}</p>
                    </div>
                </li>
                <li className="flex mb-4 p-4 bg-gray-200">
                    <div className="flex-none w-8"><img src={assets.assetsPath("images/icons/allergie/latte.png")} alt={t("allergensmodalcontent.li_6.img_1")} /></div>
                    <div className="ml-8">
                        <p><b>{t("allergensmodalcontent.li_6.b_1")}</b> {t("allergensmodalcontent.li_6.fragment_1")}</p>
                    </div>
                </li>
                <li className="flex mb-4 p-4">
                    <div className="flex-none w-8"><img src={assets.assetsPath("images/icons/allergie/lupini.png")} alt={t("allergensmodalcontent.li_7.img_1")} /></div>
                    <div className="ml-8">
                        <p><b>{t("allergensmodalcontent.li_7.b_1")}</b> {t("allergensmodalcontent.li_7.fragment_1")}</p>
                    </div>
                </li>
                <li className="flex mb-4 p-4 bg-gray-200">
                    <div className="flex-none w-8"><img src={assets.assetsPath("images/icons/allergie/molluschi.png")} alt={t("allergensmodalcontent.li_8.img_1")} /></div>
                    <div className="ml-8">
                        <p><b>{t("allergensmodalcontent.li_8.b_1")}</b> {t("allergensmodalcontent.li_8.fragment_1")}</p>
                    </div>
                </li>
                <li className="flex mb-4 p-4">
                    <div className="flex-none w-8"><img src={assets.assetsPath("images/icons/allergie/pesce.png")} alt={t("allergensmodalcontent.li_9.img_1")} /></div>
                    <div className="ml-8">
                        <p><b>{t("allergensmodalcontent.li_9.b_1")}</b> {t("allergensmodalcontent.li_9.fragment_1")}</p>
                    </div>
                </li>
                <li className="flex mb-4 p-4 bg-gray-200">
                    <div className="flex-none w-8"><img src={assets.assetsPath("images/icons/allergie/sedano.png")} alt={t("allergensmodalcontent.li_10.img_1")} /></div>
                    <div className="ml-8">
                        <p><b>{t("allergensmodalcontent.li_10.b_1")}</b> {t("allergensmodalcontent.li_10.fragment_1")}</p>
                    </div>
                </li>
                <li className="flex mb-4 p-4">
                    <div className="flex-none w-8"><img src={assets.assetsPath("images/icons/allergie/semi-sesamo.png")} alt={t("allergensmodalcontent.li_11.img_1")} /></div>
                    <div className="ml-8">
                        <p><b>{t("allergensmodalcontent.li_11.b_1")}</b> {t("allergensmodalcontent.li_11.fragment_1")}</p>
                    </div>
                </li>
                <li className="flex mb-4 p-4 bg-gray-200">
                    <div className="flex-none w-8"><img src={assets.assetsPath("images/icons/allergie/senape.png")} alt={t("allergensmodalcontent.li_12.img_1")} /></div>
                    <div className="ml-8">
                        <p><b>{t("allergensmodalcontent.li_12.b_1")}</b> {t("allergensmodalcontent.li_12.fragment_1")}</p>
                    </div>
                </li>
                <li className="flex mb-4 p-4">
                    <div className="flex-none w-8"><img src={assets.assetsPath("images/icons/allergie/soia.png")} alt={t("allergensmodalcontent.li_13.img_1")} /></div>
                    <div className="ml-8">
                        <p><b>{t("allergensmodalcontent.li_13.b_1")}</b> {t("allergensmodalcontent.li_13.fragment_1")}</p>
                    </div>
                </li>
                <li className="flex mb-4 p-4 bg-gray-200">
                    <div className="flex-none w-8"><img src={assets.assetsPath("images/icons/allergie/uova.png")} alt={t("allergensmodalcontent.li_14.img_1")} /></div>
                    <div className="ml-8">
                        <p><b>{t("allergensmodalcontent.li_14.b_1")}</b> {t("allergensmodalcontent.li_14.fragment_1")}</p>
                    </div>
                </li>
            </ul>
        </div>
    );
}
