import React from 'react';
import formats from '../../utils/formats';
import clsx from 'clsx';
import assets from '../../utils/assets';
import AllergenIcons from './AllergenIcons';

export default function IngredientList(props) {
    const { ingredient } = props;

    let icons = [];

    ingredient.has_allergens && ingredient.allergens.map((allergen) => {
        let path = assets.assetsPath(allergen.image_url);
        !icons.includes(path) && icons.push(path);
        return [];
    });

    return (
        <li className={clsx("ingredient-list", "mb-4", { "font-extrabold text-black ": ingredient.has_allergens })}>
            <span className={(ingredient.color === "#FF0000" || ingredient.color === "#EA6643") ? "font-extrabold" : null} style={{ "color": ingredient.color }}>{formats.capitalize(ingredient.name)}</span>
            {ingredient.has_allergens &&
                <span className="ml-1">
                    ({ingredient.allergens.map((allergen, index) =>
                        <span key={index}>{allergen.name}{(Boolean(Number(ingredient.allergens.length - index - 1))) ? ", " : null}</span>
                    )})
                </span>}
            <span className="inline-block ml-1"><AllergenIcons icons={icons} /></span>
        </li>
    );
}
