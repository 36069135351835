import React from "react";
import { useTranslation } from "react-i18next";
import { IonModal } from "@ionic/react";
import { useUser } from "../../utils/contexts/user-context";
import { useFilters } from "../../utils/contexts/filters-context";
import LegendModalContent from "../modals/LegendModalContent";
import formats from "../../utils/formats";
import clsx from "clsx";

export default function FilterToggle(props) {
    const { t } = useTranslation();
    const { cia } = useUser();
    const { filtered, resetFilters, setFilters } = useFilters();

    const [showLegendModal, setShowLegendModal] = React.useState(false);

    const onOpenLegendModalHandler = () => setShowLegendModal(true);
    const onCloseLegendModalHandler = () => setShowLegendModal(false);
    const onFullMenuHandler = () => {
        resetFilters();
    }
    const onPersonalMenuHandler = () => {
        setFilters(formats.legacyFilterFormatter(cia));
    }

    return (
        <div className="filter-toggle my-4">
            <div className="cursor-pointer mb-2 p-1 rounded-md bg-slate-100">
                <div className="w-full flex justify-center items-center">
                    <button className={clsx({ "flex-1 py-2 rounded-md font-bold text-sm text-center": true, "bg-gray-300 text-black": !filtered, "text-gray-500": filtered })} onClick={onFullMenuHandler}>{t("filtertoggle.button_1")}</button>
                    <button className={clsx({ "flex-1 py-2 rounded-md font-bold text-sm text-center": true, "bg-mycia-red text-white": filtered })} onClick={onPersonalMenuHandler}>{t("filtertoggle.button_2")}</button>
                </div>
            </div>

            {filtered ? <p className="my-4 mx-8 text-sm text-center text-mycia-medium">
                {t("filtertoggle.p_1")}{" "}
                <button onClick={onOpenLegendModalHandler} className="inline text-sm underline text-mycia-blue">{t("filtertoggle.button_3")}</button>
            </p> : null}

            <IonModal cssClass="legend-modal-trigger" mode="md" isOpen={showLegendModal} onDidDismiss={onCloseLegendModalHandler}>
                <LegendModalContent closeHandler={onCloseLegendModalHandler} />
            </IonModal>
        </div >
    );
}
