import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { useTranslation } from 'react-i18next';

export default function ErrorPage() {
    const { t } = useTranslation();

    return (
        <IonPage>
            <IonContent>
                <section id="error-page" className="container mx-auto pt-12 px-8 font-sans">
                    <h3 className="mb-4 text-2xl font-medium">{t("errorpage.h3_1")}</h3>
                    <h1 className="mb-4 text-4xl font-bold">{t("errorpage.h1_1")}</h1>
                    <p className="mb-8">{t("errorpage.p_1")}</p>
                    <img src="/assets/images/Img_error_404.png" alt={t("errorpage.img_1")} />
                </section>
            </IonContent>
        </IonPage>
    );
}
