import React from 'react';

export default function Openings(props) {
    const { timetables } = props;

    return (
        <>
            {timetables.map((d, index) =>
                <div className="opening flex justify-between" key={index}>
                    <p>{d.day_of_week_formatted}</p>
                    <p className="mx-3">{d.hours}</p>
                </div>
            )}
        </>
    )
}
