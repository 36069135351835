import React from 'react';
import IngredientWithAllergens from './IngredientWithAllergens';
import formats from '../../utils/formats';

export default function Ingredient(props) {
    const { ingredient, total, index } = props;

    return ingredient.has_allergens ?
        (<IngredientWithAllergens {...props} />) :
        (<span className="ingredient mr-2">{formats.capitalize(ingredient.name)}{(Boolean(Number(total - index - 1))) ? "," : null}</span>);
}
