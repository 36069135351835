import React from 'react';
import Drink from './Drink';
import Product from './Product';

export default function Items(props) {
    const { items } = props;

    return (
        <div className="items">
            {items.map((item, index) => {
                return item.is_drink ?
                    <Drink drink={item} key={index} /> :
                    <Product product={item} key={index} />;
            })}
        </div>
    );
}
