import React from 'react';
import AllergenIcons from '../UI/AllergenIcons';
import DrinkModalTrigger from '../modals/DrinkModalTrigger';
import PreferredButton from '../UI/PreferredButton';
import assets from '../../utils/assets';
import formats from '../../utils/formats';
import { useRestaurant } from '../../utils/contexts/restaurant-context';
import { useParams } from 'react-router';
import { useTracker } from '../../utils/contexts/tracker-context';
import parse from "html-react-parser";
import clsx from "clsx";

export default function Drink(props) {
    const { drink } = props;

    const { restaurant } = useRestaurant();
    const { type } = useParams();
    const { trackDataFn } = useTracker();

    const [showDrinkModal, setShowDrinkModal] = React.useState(false);
    const [description, setDescription] = React.useState("");

    let icons = [];
    let name = formats.formatDrinkName(drink);

    (drink.has_sulfites) && icons.push(assets.assetsPath("images/icons/allergie/solfiti.png"));

    async function onDetailsOpenHandler() {
        await trackDataFn(restaurant.id, restaurant.token, "drinkview", { "type": type, "name": drink.name, "is_campari": drink.is_campari, "id": drink.id, "price": drink.price }, restaurant.has_orders);

        // try {
        //     await window.fbq("track", "ViewContent");
        // } catch (e) {
        //     console.log({ pixelError: e })
        // } finally {
        setShowDrinkModal(true);

        // }
    };

    React.useEffect(() => {
        setDescription(parse(formats.formatDescription(drink).replace(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g, formats.replacer)))
    }, [drink]);

    return (
        <div className="drink p-2">
            <div className="flex rounded-lg shadow bg-grey-100">
                <div className="flex-1 py-2 px-4">
                    <p className={clsx("font-semibold", { "capitalize": restaurant.id === 14857 })}>{name}</p>
                    {restaurant.id === 9440 || restaurant.id === 10158 ?
                        formats.formatMotuekaDescription(drink.description).map(d =>
                            <p className="text-sm">{d}</p>) :
                        <p className="text-sm">{description}</p>}

                    <AllergenIcons icons={icons} /> {formats.shouldDisplayDrinkDetails(drink) ? <DrinkModalTrigger drink={drink} showDrinkModal={showDrinkModal} setShowDrinkModal={setShowDrinkModal} onDetailsOpenHandler={onDetailsOpenHandler} /> : null}
                </div>
                <div className="flex flex-col items-end py-2 pr-4 text-base">
                    <PreferredButton item={drink} drink={true} />

                    {formats.cleanPrice(drink.price) !== "" ? <p className="mt-2 font-bold">{formats.formatPrice(drink.price, restaurant.id)}</p> : null}
                    {formats.shouldDisplayPrice(drink, "glass_price") ?
                        <p className="mt-2 font-bold">Calice: {formats.formatPrice(drink.glass_price, restaurant.id)}</p> : null}

                    {(typeof drink.image_path === "string" && drink.image_path !== "") ? <img className="object-cover w-26 h-26 my-3 border border-grey-300 rounded-lg" onClick={onDetailsOpenHandler} src={drink.image_path} alt="drink"></img> : null}
                </div>
            </div>
        </div>
    );
}
