import React from 'react';
import { IonPopover, IonIcon } from '@ionic/react';
import { close, informationCircle } from 'ionicons/icons';
import TimetablesOrder from './TimetablesOrder';
import './OrderTimetablesPopoverStyle.css';

export default function OrderTimetablesPopover(props) {
    const { orders } = props;
    const [showPopover, setShowPopover] = React.useState(false);

    function closerHandler() {
        setShowPopover(false);
    }

    return (
        <React.Fragment>
            <IonPopover
                isOpen={showPopover}
                onDidDismiss={e => setShowPopover(false)}
                cssClass="custom-popover"
            >
                <div className="order-timetables-popover">
                    <div className="mb-2 px-4" style={{ "minHeight": "2rem" }}>
                        <button type="button" onClick={closerHandler} className="z-10 absolute top-0 right-0 flex items-center justify-center mt-4 p-3 pb-0">
                            <IonIcon slot="icon-only" icon={close}></IonIcon>
                        </button>
                    </div>
                    <TimetablesOrder orders={orders} />
                </div>
            </IonPopover>
            <IonIcon slot="icon-only" size="large" className="px-3" icon={informationCircle} onClick={() => setShowPopover(true)}></IonIcon>
        </React.Fragment>
    );
};
