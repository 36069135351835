import React from 'react';
import Product from './Product';
import formats from '../../utils/formats';
import clsx from 'clsx';

export default function Products(props) {
    let { products, index, isTastingMenu } = props;

    let classnames = [
        "products",
        { "even bg-white": formats.isEven(index) },
        { "odd bg-grey-200": !formats.isEven(index) }
    ];

    return (
        <div className={clsx(classnames)}>
            {0 === products.length ?
                (<p className="text-center pb-1">Nessun prodotto rispetta le tue preferenze.</p>) :
                (products.map((product, index) => <Product product={product} key={index} isTastingMenu={isTastingMenu} />))}
        </div>
    );
}
