import React from 'react';

export default function Wisher(props) {
    const { onQuantityHandler } = props;

    const [quantity, setQuantity] = React.useState(1);

    const onSubOneHandler = (e) => {
        e.preventDefault();
        let aquantity = quantity > 1 ? quantity - 1 : 1;
        setQuantity(aquantity);
    }
    const onAddOneHandler = (e) => {
        e.preventDefault();
        setQuantity(quantity + 1);
    }

    React.useEffect(() => {
        onQuantityHandler(quantity);
    }, [quantity, onQuantityHandler]);

    return (
        <div className="wisher flex justify-center items-center text-xl font-bold">
            <button type="button" onClick={onSubOneHandler} className="p-4">-</button>
            <span className="quantity mx-2 py-2 px-4 rounded-full border-2 border-mycia-blue">{quantity}</span>
            <button type="button" onClick={onAddOneHandler} className="p-4">+</button>
        </div>
    );
}
