import clsx from 'clsx';
import React from 'react';

export default function DrinkVariations(props) {
    const { product, changed, setChanged } = props;

    const [enable, setEnable] = React.useState("");

    React.useEffect(() => {
        if (changed) {
            setEnable("border-solid border-l-8 border-mycia-green");
        } else {
            setEnable("border-solid border-l-8 border-transparent");
        }
    }, [changed])

    function onChangeHandler(e) {
        if (e.target.checked) {
            product.use_glass_price = true;
        } else {
            if (product.use_glass_price) {
                product.use_glass_price = false;
            }
        }
        setChanged(e.target.checked);
    }

    return (
        <div className="drink-variations p-3">
            <p className="">Che variante vuoi ordinare?</p>
            <div className={`flex items-center mt-4 rounded-lg bg-grey-200 ${enable}`}>
                <div className="flex-1">
                    <input type="checkbox" id="variants" name="variants" className="hidden" onChange={onChangeHandler} />
                    <label className={clsx(["cursor-pointer select-none block p-4", { "font-bold": changed }])} htmlFor="variants">Calice</label>
                </div>
                <label className="py-4 px-3" htmlFor="variants">€ {product.glass_price}</label>
            </div>
        </div>
    )
}
