import React from 'react';

export default function RedBullModalContent(props) {
    const { onClickHandler } = props;

    return (
        <React.Fragment>
            <button className="absolute top-2 right-2 bg-[#F2F2F2] rounded-full z-50" onClick={onClickHandler}>
                <svg xmlns="http://www.w3.org/2000/svg" className="w-8 text-black" viewBox="0 0 512 512">
                    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368 144 144m224 0L144 368" />
                </svg>
            </button>

            <img src="/assets/redbull/modal.jpg" className="mx-auto mt-4 scale-x-[95%] object-cover" alt="redbull logo" />

            <div className="discover-more absolute bottom-0 w-full flex justify-center mb-6">
                <a href="https://www.redbull.com/it-it/" target="_blank" rel="noopener noreferrer" className="block py-2 text-center text-white w-[calc(100%-2rem)] bg-[#071B37] font-bold rounded-full uppercase">scopri di più</a>
            </div>
        </React.Fragment>
    )
}
