
export default function Cia(props) {
    const { cia } = props;

    return (
        <div>
            <p className="cia mx-auto mt-8 mb-4 font-bold text-3xl text-center">Il tuo profilo</p>

            <div className="mx-auto px-4 flex flex-col">
                <div className="flex flex-col items-center pt-2 pb-4 shadow-xl rounded-md border border-slate-300 text-black">
                    <img className="mb-4 py-4 px-8" src="/assets/cia-full-logo.png" alt="mycia full logo" />

                    <dl className="w-full flex flex-row flex-wrap px-4">
                        <dt className="w-full font-bold text-lg">Regime alimentare</dt>
                        <dd className="w-full">
                            <ul className="list-disc ml-6">
                                {cia.needs.filter(x => x.type === "diet").length > 0 ?
                                    cia.needs.filter(x => x.type === "diet").map((element, idx) => (
                                        <li key={idx} className="font-light">{element.name}</li>
                                    )) :
                                    <li className="text-slate-400">Nessuno</li>
                                }
                            </ul>
                        </dd>

                        <dt className="w-full mt-2 pt-2 font-medium text-lg">Alimenti non graditi</dt>
                        <dd className="w-full">
                            <ul className="list-disc ml-6">
                                {cia.demands.filter(x => x.type === "aversion").length > 0 ?
                                    cia.demands.filter(x => x.type === "aversion").map((element, idx) => (
                                        <li key={idx} className="font-light">{element.name}</li>
                                    )) :
                                    <li className="text-slate-400">Nessuno</li>
                                }
                            </ul>
                        </dd>

                        <dt className="w-full mt-2 pt-2 font-medium text-lg">Allergie</dt>
                        <dd className="w-full">
                            <ul className="list-disc ml-6">
                                {cia.needs.filter(x => x.type === "allergen").length > 0 ?
                                    cia.needs.filter(x => x.type === "allergen").map((element, idx) => (
                                        <li key={idx} className="font-light">{element.name}</li>
                                    )) :
                                    <li className="text-slate-400">Nessuna</li>
                                }
                            </ul>

                            <ul className="list-disc ml-6">
                                {cia.demands.filter(x => x.type === "allergy").length > 0 ?
                                    cia.demands.filter(x => x.type === "allergy").map((element, idx) => (
                                        <li key={idx} className="font-light">{element.name}</li>
                                    )) : null}
                            </ul>
                        </dd>

                        <dt className="w-full mt-2 pt-2 font-medium text-lg">Cibi mal digeriti</dt>
                        <dd className="w-full">
                            <ul className="list-disc ml-6">
                                {cia.demands.filter(x => x.type === "intolerance").length > 0 ?
                                    cia.demands.filter(x => x.type === "intolerance").map((element, idx) => (
                                        <li key={idx} className="font-light">{element.name}</li>
                                    )) :
                                    <li className="text-slate-400">Nessuno</li>
                                }
                            </ul>
                        </dd>

                        <dt className="w-full mt-2 pt-2 text-lg">Condizioni alimentari</dt>
                        <dd className="w-full">
                            <ul className="list-disc ml-6">
                                {cia.needs.filter(x => x.type === "pathology").length > 0 ?
                                    cia.needs.filter(x => x.type === "pathology").map((element, idx) => (
                                        <li key={idx} className="font-light">{element.name}</li>
                                    )) :
                                    <li className="text-slate-400">Nessuna</li>
                                }
                            </ul>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    );
}

