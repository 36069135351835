import React from 'react';
import Drink from './Drink';
import formats from '../../utils/formats';
import clsx from 'clsx';

export default function Drinks(props) {
    let { drinks, index, category } = props;

    let classnames = [
        "drinks",
        { "even bg-white": formats.isEven(index) },
        { "odd bg-grey-200": !formats.isEven(index) },
        { "sanbitter-bg": process.env.REACT_APP_NAMESPACE === "sanpellegrino" && category.custom_theme_sanbitter === true },
        { "sanbitter-bg": process.env.REACT_APP_NAMESPACE === "sanpellegrino" && category.custom_theme_sanbitter === true },
        { "pago-bg": process.env.REACT_APP_NAMESPACE === "sanpellegrino" && category.custom_theme_pago === true },
    ];

    return (
        <div className={clsx(classnames)}>
            {0 === drinks?.length ?
                (<p>No drinks.</p>) :
                (drinks?.map((drink, index) => <Drink drink={drink} key={index} />))}
        </div>
    );
}
