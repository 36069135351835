import { IonModal } from '@ionic/react';
import React from 'react';
import RedBullModalContent from './RedBullModalContent';
import "./RedBullModalContentStyle.css"

export default function RedBullModalTrigger(props) {
    const { setCouponSentinel } = props;

    const [showModal, setShowModal] = React.useState(false);

    const onCloseHandler = () => setShowModal(false);

    React.useEffect(() => {
        setTimeout(() => setShowModal(true), 1000);
    }, []);

    return (
        <IonModal cssClass="redbull-modal-content" mode="md" isOpen={showModal} onDidDismiss={onCloseHandler}>
            <RedBullModalContent setCouponSentinel={setCouponSentinel} onClickHandler={onCloseHandler} />
        </IonModal>
    );
}
