import React from "react";
import { useHistory, useParams } from "react-router";
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement, PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import { useRestaurant } from "../../utils/contexts/restaurant-context";
import stripeClient from '../../logic/stripe';
import { useTranslation } from "react-i18next";

export default function StripeWidgetContent(props) {
    const { order } = props;

    const { type, id } = useParams();
    const history = useHistory();
    const { restaurant } = useRestaurant();

    const [error, setError] = React.useState(null);
    const [clientSecret, setClientSecret] = React.useState("");
    const [paymentRequest, setPaymentRequest] = React.useState(null);

    const stripe = useStripe();
    const elements = useElements();
    const { t } = useTranslation();

    React.useEffect(() => {
        initStripePayment();

        async function initStripePayment() {

            // Create PaymentIntent as soon as the page loads
            const payload = {
                "amount": order.order.total * 100,
                "currency": "eur",
                "description": JSON.stringify({ ris_id: order.restaurant.id, amount: order.order.total }),
                "type": order.order.type,
            }

            const res = await stripeClient.createCheckout(payload);
            if (res) {
                setClientSecret(res.client_secret);
            } else {
                setError("Errore nell'elaborazione del pagamento, riprova più tardi");
            }
        }

    }, [order.order.total, order.order.type, order.restaurant.id]);

    React.useEffect(() => {
        if (!stripe || !elements || !clientSecret) {
            return;
        }

        const pr = stripe.paymentRequest({
            country: "IT",
            currency: "eur",
            total: {
                label: restaurant.name,
                amount: order.order.total * 100,
            }
        });

        pr.canMakePayment().then(result => {
            if (result) {
                setPaymentRequest(pr);
            }
        });
    }, [stripe, elements, clientSecret, order.order.total, restaurant.name])

    const cardStyle = {
        style: {
            base: {
                color: '#303238',
                fontSize: '20px',
                fontFamily: '"Nunito", sans-serif',
                fontSmoothing: 'antialiased',
                '::placeholder': {
                    color: '#CFD7DF',
                },
            },
            invalid: {
                color: '#e5424d',
                ':focus': {
                    color: '#303238',
                },
            },
        },
        showIcon: true,
    };

    const cardNumber = {
        style: {
            base: {
                color: '#303238',
                fontSize: '20px',
                fontFamily: '"Nunito", sans-serif',
                fontSmoothing: 'antialiased',
                '::placeholder': {
                    color: '#CFD7DF',
                },
            },
            invalid: {
                color: '#e5424d',
                ':focus': {
                    color: '#303238',
                },
            },
        },
        showIcon: true,
    };

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setError(event.error ? event.error.message : "");
    };

    const onClickPaymentRequest = () => {
        paymentRequest.on("paymentmethod", async (event) => {
            const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: event.paymentMethod.id,
            }, { handleActions: false });

            if (paymentIntent.status === "requires_action") {
                stripe.confirmCardPayment(clientSecret);
            }

            if (stripeError) {
                setError(stripeError.message)
                event.complete("fail");
            } else {
                event.complete("success");
                setError(null);
                if (type === "t" || type === "d") {
                    history.push(`/${type}/q/${id}/orders/awaitsms/?provider=stripe&transactionid=${paymentIntent.id}`);
                }
                if (type === "r") {
                    history.push(`/${type}/q/${id}/orders/success/?provider=stripe&transactionid=${paymentIntent.id}`);
                }
            }
        });
    };

    const handleSubmit = async ev => {
        ev.preventDefault();

        const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardNumberElement)
            }
        });

        if (payload.error) {
            setError(`Payment failed ${payload.error.message}`);
        } else {
            setError(null);
            if (type === "t" || type === "d") {
                history.push(`/${type}/q/${id}/orders/awaitsms/?provider=stripe&transactionid=${payload.paymentIntent.id}`);
            }
            if (type === "r") {
                history.push(`/${type}/q/${id}/orders/success/?provider=stripe&transactionid=${payload.paymentIntent.id}`);
            }
        }
    };

    return (
        <div className="stripe-widget-content">
            <form className="relative p-6 py-10" onSubmit={handleSubmit}>
                <div className="pb-3">
                    <p className="text-xl">Numero carta</p>
                    <div className="p-3 border bg-white rounded-lg">
                        <CardNumberElement
                            options={cardNumber}
                            onReady={() => {
                                console.log("CardNumberElement [ready]");
                            }}
                            onChange={handleChange}
                            onBlur={() => {
                                console.log("CardNumberElement [blur]");
                            }}
                            onFocus={() => {
                                console.log("CardNumberElement [focus]");
                            }}
                        />
                    </div>
                </div>

                <div className="py-3">
                    <div className="flex">
                        <div className="flex-1 mr-2">
                            <p className="text-xl">Data di scadenza</p>
                            <div className="p-3 bg-white rounded-lg">
                                <CardExpiryElement
                                    options={cardStyle}
                                    onReady={() => {
                                        console.log("CardNumberElement [ready]");
                                    }}
                                    onChange={handleChange}
                                    onBlur={() => {
                                        console.log("CardNumberElement [blur]");
                                    }}
                                    onFocus={() => {
                                        console.log("CardNumberElement [focus]");
                                    }}
                                />
                            </div>
                        </div>

                        <div className="flex-1 ml-2">
                            <p className="text-xl">CVC</p>
                            <div className="p-3 bg-white rounded-lg">
                                <CardCvcElement
                                    options={cardStyle}
                                    onReady={() => {
                                        console.log("CardNumberElement [ready]");
                                    }}
                                    onChange={handleChange}
                                    onBlur={() => {
                                        console.log("CardNumberElement [blur]");
                                    }}
                                    onFocus={() => {
                                        console.log("CardNumberElement [focus]");
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <p className="text-red-600">{error}</p>

                <div className="fixed bottom-0 left-0 w-full mt-8 p-4 py-8">
                    <div className="flex py-3 items-center justify-center">
                        <input type="checkbox" id="agreements" name="agreements" required />
                        <p className="text-xs pl-2">{t("formtable.lable_1.span_1")} <a target="_blank" className="underline" href="https://www.cartaidentitalimentare.com/privacy-esercizi-commerciali/" rel="noreferrer">{t("formtable.lable_1.a_1")}</a></p>
                    </div>

                    {paymentRequest && <PaymentRequestButtonElement options={{ paymentRequest }} onClick={onClickPaymentRequest} />}

                    <button className="w-full mt-4 py-2 rounded-full bg-mycia-green" type="submit" disabled={!stripe}>PAGA</button>
                </div>
            </form>
        </div>
    );
}
