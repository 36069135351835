import React from 'react';
import { IonModal } from '@ionic/react';
import TimeDiscountModalContent from './TimeDiscountModalContent';

export default function TimeDiscountModalTrigger(props) {
    const [showModal, setShowModal] = React.useState(false);

    const onCloseHandler = () => setShowModal(false);

    React.useLayoutEffect(() => {
        setTimeout(() => setShowModal(true), 5000);
    }, []);

    return (
        <div className="time-discount-modal-trigger py-1">
            <IonModal cssClass="time-discount-modal-trigger" mode="md" isOpen={showModal} onDidDismiss={onCloseHandler}>
                <TimeDiscountModalContent onClickHandler={onCloseHandler} />
            </IonModal>
        </div>
    );
}
