import React from 'react';
import clsx from 'clsx';

export default function BetterWith(props) {
    const { product, buyedSuggestedDrink, setBuyedSuggestedDrink } = props;

    const [message, setMessage] = React.useState("Aggiungi al tuo ordine");

    let classnames = [
        "better-with-button py-2 border border-green-400 text-center rounded-b-lg",
        { "bg-white text-mycia-green": !Boolean(buyedSuggestedDrink) }
    ];

    function handleChange(e) {
        setBuyedSuggestedDrink(e.target.checked);
        if (e.target.checked) {
            setMessage("Ordine aggiunto al carrello");
        } else {
            setMessage("Aggiungi al tuo ordine");
        }
    }

    return (
        <div className="better-with m-3 mt-6">
            <div className="">
                <div className="flex w-full px-3 py-2 rounded-t-lg border-l-2 border-t-2 border-r-2">
                    {product.suggested_drink.image_path !== "" && <img className="flex-initial mx-0 w-24 h-24 rounded-xl" src={product.suggested_drink.image_path} alt="suggested drink"></img>}
                    <div className="flex-1 pl-2">
                        <p className="font-bold">Ottimo con:</p>
                        <p>{product.suggested_drink.name}</p>

                        {product.suggested_drink.description !== "" && <span className="flex">-<p className="text-sm"><div className="pl-3">{product.suggested_drink.description}</div></p></span>}

                        <p className="font-bold text-sm">Prezzo: {product.suggested_drink.price} €</p>
                    </div>

                </div>
            </div>

            <div className={clsx(classnames)}>
                <input type="checkbox" id="message" className="text-black w-full" style={{ "display": "none" }} onClick={handleChange}></input>
                <label htmlFor="message">{message}</label>
            </div>

        </div >
    )
}
