import React from 'react';
import { useRestaurant } from '../../utils/contexts/restaurant-context';
import Drink from '../list/Drink';
import './TimeDiscountModalContentStyle.css';

export default function TimeDiscountModalContent(props) {
    // const { onClickHandler } = props;

    const { restaurant } = useRestaurant();

    // const { t } = useTranslation();

    let products = [];
    if (restaurant.beverages.length > 0 && restaurant.beverages[0].drinks.length > 0) {
        products.push(restaurant.beverages[0].drinks[0]);
    }

    return (
        <div className="time-discount-modal-content overflow-auto text-black ">
            {process.env.REACT_APP_NAMESPACE === "pago" ?
                <div className="p-3">
                    <p className="text-center">Inizia al meglio la tua giornata.</p>
                    <p className="text-center">Goditi la tua colazione con <span className="text-[#E10B25]">PAGO</span></p>
                </div> :
                process.env.REACT_APP_NAMESPACE === "sanbitter" ?
                    <div>
                        <p className="text-center p-3">Lasciati ispirare con <span className="text-[#E10B25]">SANBITTER</span> per trasformare ogni tuo aperitivo in un momento piacevole ed intenso</p>
                    </div> :
                    process.env.REACT_APP_NAMESPACE === "redbull" ?
                        <div>
                            <p className="text-center p-3 text-white">Sono arrivate le <span className="text-[#E10B25]">ORGANICS</span> by Red Bull. Prova tutte le varianti di gusto
                            </p>
                        </div> :
                        null}

            <div className="discount-products">
                {0 === products.length ?
                    (<p>No products.</p>) :
                    (products.map((drink, index) => <Drink drink={drink} key={index} isTastingMenu={false} />))}
            </div>
        </div>
    );
}
