import React from 'react';
import { IonRouterOutlet } from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';

/** Pages */
import HomePage from './pages/HomePage';
import SplashPage from './pages/SplashPage';
import MenuPage from './pages/MenuPage';
import ErrorPage from './pages/ErrorPage';
import OrdersPage from './pages/OrdersPage';
import TablesPage from './pages/TablesPage';
import PaymentsPage from './pages/PaymentsPage';
import SuccessPage from './pages/SuccessPage';
import OrdersErrorPage from './pages/OrdersErrorPage';
import OrdersCancelPage from './pages/OrdersCancelPage';
import OrderType from './pages/OrderTypePage';
import DeliveryOrderPage from './pages/DeliveryOrderPage';
import TakeawayOrderPage from './pages/TakeawayOrderPage';
import OrderSuccessPage from './pages/OrderSuccessPage';
import ShipmentOrderPage from './pages/ShipmentOrderPage';
import WhatsapSuccessPage from './pages/WhatsappSuccessPage';
import GuestSharingSuccessPage from './pages/GuestSharingSuccessPage';
import InfoPage from './pages/InfoPage';
import BookingRestaurantPage from './pages/BookingRestaurantPage';
import BookingCompletedPage from './pages/BookingCompletedPage';
import UltraBrasserieSplashPage from './pages/UltraBrasserieSplashPage';
import AuthCallback from "./pages/AuthCallback";
import ProfilePage from "./pages/ProfilePage";
import ReviewPage from './pages/ReviewPage';
import ReviewSuccessPage from './pages/ReviewSuccessPage';
import TipSuccessPage from './pages/TipSuccessPage';

export default function AppRoutes() {
    return (
        <IonRouterOutlet>
            <Redirect from="/q/:id" to="/r/q/:id" />
            <Redirect from="/q/:id/menu" to="/r/q/:id/menu" />
            <Route exact path="/">
                <HomePage />
            </Route>
            <Route exact path="/profile">
                <ProfilePage />
            </Route>
            <Route exact path="/:type/q/:id/info">
                <InfoPage />
            </Route>
            <Route exact path="/:type/q/:id/bookingrestaurant">
                <BookingRestaurantPage />
            </Route>
            <Route exact path="/:type/q/:id/bookingcompleted">
                <BookingCompletedPage />
            </Route>
            <Route exact path="/:type/q/:id/orders/success">
                <SuccessPage />
            </Route>
            <Route exact path="/:type/q/:id/orders/awaitsms">
                <OrderSuccessPage />
            </Route>
            <Route exact path="/:type/q/:id/orders/payments">
                <PaymentsPage />
            </Route>
            <Route exact path="/:type/q/:id/orders/tables">
                <TablesPage />
            </Route>
            <Route exact path="/:type/q/:id/orders/type">
                <OrderType />
            </Route>
            <Route exact path="/:type/q/:id/orders/delivery">
                <DeliveryOrderPage />
            </Route>
            <Route exact path="/:type/q/:id/orders/takeaway">
                <TakeawayOrderPage />
            </Route>
            <Route exact path="/:type/q/:id/orders/shipment">
                <ShipmentOrderPage />
            </Route>
            <Route exact path="/:type/q/:id/orders/error">
                <OrdersErrorPage />
            </Route>
            <Route exact path="/:type/q/:id/orders/cancel">
                <OrdersCancelPage />
            </Route>
            <Route exact path="/:type/q/:id/orders">
                <OrdersPage />
            </Route>
            <Route exact path="/:type/q/:id/menu">
                <MenuPage />
            </Route>
            <Route exact path="/:type/q/:id/auth/callback">
                <AuthCallback />
            </Route>
            <Route exact path="/:type/q/:id">
                <SplashPage />
            </Route>
            <Route exact path="/:type/q/:id/ultrabrasserie">
                <UltraBrasserieSplashPage />
            </Route>
            <Route exact path="/:type/q/:id/orders/whatsapp">
                <WhatsapSuccessPage />
            </Route>
            <Route exact path="/:type/q/:id/orders/sharesuccess">
                <GuestSharingSuccessPage />
            </Route>
            <Route exact path="/:type/q/:id/review">
                <ReviewPage />
            </Route>
            <Route exact path="/:type/q/:id/review/success">
                <ReviewSuccessPage />
            </Route>
            <Route exact path="/:type/q/:id/tip/success">
                <TipSuccessPage />
            </Route>
            <Route path="/404">
                <ErrorPage />
            </Route>
            <Redirect to="/404" />
        </IonRouterOutlet>
    );
}
