import Nutrient from "./Nutrient";

export default function Nutrients(props) {
    const { nutrients } = props;

    return (
        <dl className="nutrients">
            {Object.entries(nutrients).map((nutrient, index) => {
                return <Nutrient nutrient={nutrient} index={index} />;
            })}
        </dl>
    );
}
