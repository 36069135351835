import React from 'react';
import { usePreferences } from '../../utils/contexts/preferences-context';
import clsx from 'clsx';

export default function PreferredButton(props) {
    const { item, drink } = props;

    const [preferred, setPreferred] = React.useState(false);

    React.useLayoutEffect(() => {
        setPreferred(item.is_preferred);
    }, [item]);

    const { add, remove } = usePreferences();

    const onToggleHandler = () => {
        item.is_drink = drink ? true : false;

        if (preferred) {
            item.is_preferred = false;
            remove(item.id);
            setPreferred(false);
        } else {
            item.is_preferred = true;
            add(item);
            setPreferred(true);
        }
    }

    return (
        <button type="button" className={clsx(["preferred-button", "w-5 h-5 outline-none", { "preferred": preferred }])} onClick={onToggleHandler}>
            {preferred ?
                (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57.42 54.73" className="fill-current text-mycia-red">
                        <g data-name="Livello 2">
                            <path d="M57.42 20.86l-17.69 13 6.79 20.86-17.81-12.88L10.9 54.73l6.8-20.86L0 20.86h21.91L28.71 0l6.8 20.86z" />
                        </g>
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57.42 54.73" className="fill-current text-mycia-red">
                        <g data-name="Livello 2">
                            <path d="M57.42 20.86l-17.69 13 6.79 20.86-17.81-12.88L10.9 54.73l6.8-20.86L0 20.86h21.91L28.71 0l6.8 20.86zM39.73 45.47l-4.22-13 11.13-8.08H32.93l-4.22-13-4.22 13H10.78l11.13 8.08-4.21 13 11-8.09z" />
                        </g>
                    </svg>
                )}
        </button>
    );
}
