import React from 'react';
import CartSharingHost from './CartSharingHost';
import CartSharingGuest from './CartSharingGuest';

export default function CartSharingFork() {
    const urlParams = new URLSearchParams(window.location.search);
    const shareId = urlParams.get("shareid")

    return (
        <div className="cart-sharing-fork">
            {shareId ?
                <CartSharingGuest />
                :
                <CartSharingHost />
            }
        </div>
    );
}
