import { IonModal } from '@ionic/react';
import React from 'react';
import CouponModalContent from './CouponModalContent';

export default function CouponModalTrigger(props) {
    const { setCouponSentinel } = props;

    const [showModal, setShowModal] = React.useState(false);

    const onCloseHandler = () => setShowModal(false);

    React.useLayoutEffect(() => {
        const r = sessionStorage.getItem('coupon');
        if (!r) {
            setTimeout(() => setShowModal(true), 1000);
        }
    }, []);

    return (
        <div className="coupon-modal-trigger py-1">
            <IonModal cssClass="delivery-popup-modal-trigger" mode="md" isOpen={showModal} onDidDismiss={onCloseHandler}>
                <CouponModalContent setCouponSentinel={setCouponSentinel} onClickHandler={onCloseHandler} />
            </IonModal>
        </div>
    );
}
